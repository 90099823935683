import axios from "axios";
import { toast } from "react-toastify";
import { history } from "../history";
import React from 'react'
import { FormattedMessage } from "react-intl";
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const request = axios.create({
  baseURL: "https://api.florinz.com/",
  timeout: 15000,
  cancelToken: source.token,
});

request.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const { response } = error;
    if (axios.isCancel(error)) {
    }
    if (response?.status !== 401) {
      //try to get the language
      if (response?.data.error_en) {
        toast.error(<FormattedMessage id={response?.data?.error ?? "unknown error"} />);
      } else if (
        response?.data?.error && 
        !response?.config?.url?.includes("menu/report/") &&
        !response?.data?.error?.includes("not paid") && 
        !response?.data?.error.includes('This prize is assigned to')) {
        toast.error(<FormattedMessage id={response?.data?.error ?? "unknown error"} />);
      }
    }
    if (response?.status === 401) {
      if (response?.data?.error)
        toast.error(<FormattedMessage id={response?.data?.error ?? "unknown error"} />);
      localStorage.removeItem("token");
      history.push("/");
    }
    return Promise.reject(error);
  }
);

export default request;

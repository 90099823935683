import React, { useContext, useState } from 'react';
import { HelpCircle, Star , LifeBuoy} from 'react-feather';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { IntlContext } from '../utility/context/Internationalization';
import {history} from '../history'
import './styles.css'

const FloatingMenuButton = () => {
    const [open, setOpen] = useState(false)
    const context = useContext(IntlContext);

    const fabStyle = {
        position: 'fixed',
        bottom: '5%',
        right: context.state.locale === "en" ? '80px' : undefined,
        left: context.state.locale === "sa" ? '80px' : undefined,
        zIndex: 100,
        boxShadow: 'rgb(116, 94, 197) 0px 1px 20px 1px',
        borderRadius: '0.4285rem', 
        border: 'none', 
    };

    const toggleDropDown = () => {
        setOpen(!open);
    };

  return (
        <ButtonDropdown isOpen={open} toggle={toggleDropDown} direction='up' style={fabStyle} className='mobile-float-button'>
            <DropdownToggle color='primary' caret style={{padding: '0.51rem 0.53rem'}}>
                <HelpCircle size={25} style={{left: 0, right: 0}}/>
            </DropdownToggle>
            <DropdownMenu className='pt-0 pb-0 dropdown-menu-rtl-mode floating-menu' 
                style={{
                    backgroundColor: "transparent",
                    border: "none",
                    marginLeft: context.state.locale !== "en" && '-1.3rem',
                    marginRight: context.state.locale === "en" && '3.5rem',

                }}>
                <Form>                    
                    <DropdownItem>      
                        <div className='d-flex align-items-center flex-row-reverse pl-2' >
                            <div className='circular-icon ml-1 p-50'>
                                <Star size={20} className='white'/>          
                            </div>
                            <h5 className="cursor-pointer mb-0 px-1 py-50 rounded bg-secondary" onClick={() => window.location.replace('https://qr.florinz.com/evaluate/100/127')}><FormattedMessage id="Rate Us"/></h5>
                        </div>      
                    </DropdownItem>
                    <DropdownItem>    
                        <div className='d-flex align-items-center flex-row-reverse pl-2' onClick={() => history.push('/raise-support')}>
                            <div className='circular-icon ml-1 p-50'>
                                <LifeBuoy size={20} className='white'/>    
                            </div>
                            <h5 className="cursor-pointer mb-0  px-1 py-50 rounded bg-secondary" ><FormattedMessage id="Raise Support"/></h5>
                        </div>     
                    </DropdownItem>
                </Form> 
            </DropdownMenu>
        </ButtonDropdown>
  );
};

export default FloatingMenuButton;
import { createStore, applyMiddleware, compose } from "redux"
import createDebounce from "redux-debounced"
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from "redux-thunk"
import rootReducer from "../reducers/rootReducer"

const rootPersistConfig = {
  key: 'root',
  storage: storage,
}
const middlewares = [thunk, createDebounce()]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const persistedReducer = persistReducer(rootPersistConfig, rootReducer)
const store = createStore(
  persistedReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
)
let persistor = persistStore(store)
export { store, persistor }

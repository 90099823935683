import React from "react";
import * as Icon from "react-feather";
import wheelSVG from "../assets/img/svg/lottery-wheel.svg";
import physicalWheelSVG from "../assets/img/svg/physical-lottery-wheel.svg";

const navigationConfig = [
  {
    id: "Dashboard",
    title: "Dashboard",
    type: "item",
    permissions: ["1", "2"],
    icon: <Icon.Home size={20} />,
    navLink: "/",
  },
  {
    id: "report",
    title: "Reports",
    type: "collapse",
    icon: <Icon.FileText size={20} />,
    children: [
      {
        id: "reports",
        title: "Reports",
        type: "item",
        icon: <Icon.List size={20} />,
        navLink: "/reports",
        permissions: ["1", "2"],
      },
      {
        id: "kpi",
        title: "KPI",
        type: "item",
        icon: <Icon.CheckSquare size={20} />,
        navLink: "/kpi",
        permissions: ["1", "2"],
      },
    ],
  },
  {
    id: "menu",
    title: "Menus",
    type: "collapse",
    icon: <Icon.Map size={20} />,
    children: [
      {
        id: "menus",
        title: "Menus List",
        type: "item",
        icon: <Icon.List size={20} />,
        navLink: "/menus",
        permissions: ["1", "2"],
      },
      {
        id: "orders",
        title: "Orders",
        restrict_to_ordering: true,
        type: "item",
        icon: <Icon.ShoppingCart size={20} />,
        navLink: "/orders",
        permissions: ["1", "2", "3"],
      },

      {
        id: "tables",
        title: "Tablesx",
        type: "item",
        icon: <Icon.Grid size={20} />,
        navLink: "/tables",
        restrict_to_ordering: true,
        permissions: ["1", "2"],
      },
      {
        id: "modifiers",
        title: "Modifiers",
        type: "item",
        icon: <Icon.Layers size={20} />,
        navLink: "/modifiers",
        permissions: ["1", "2"],
      },
    ],
    permissions: ["1", "2", "3"],
  },
  {
    id: "evaluation",
    title: "Evaluation",
    type: "collapse",
    icon: <Icon.Edit3 size={20} />,
    children: [
      {
        id: "reviewsForm",
        title: "Evaluation Forms",
        type: "item",
        icon: <Icon.List size={20} />,
        navLink: "/reviews-form",
        permissions: ["1", "2"],
      },
      {
        id: "reviewsResults",
        title: "Evaluation Results",
        type: "item",
        icon: <Icon.BarChart2 size={20} />,
        navLink: "/reviews-results",
        permissions: ["1", "2"],
      },
    ],
    permissions: ["1", "2"],
  },
  {
    id: "lottery",
    title: "Lottery Wheel",
    type: "collapse",
    icon: <Icon.Target size={20} />,
    children: [
      {
        id: "lotteryWheel",
        title: "Digital Wheel",
        type: "item",
        icon: <img src={wheelSVG} style={{ width: 25 }} className="mr-1" />,
        navLink: "/lottery-wheels",
        permissions: ["1", "2"],
      },
      {
        id: "physicalLotteryWheel",
        title: "Physical Wheel",
        type: "item",
        icon: (
          <img src={physicalWheelSVG} style={{ width: 30 }} className="mr-1" />
        ),
        navLink: "/physical-lottery-wheels",
        permissions: ["1", "2"],
      },
      {
        id: "lotteryWheelResults",
        title: "Lottery Results",
        type: "item",
        icon: <Icon.BarChart2 size={20} />,
        navLink: "/lottery-results",
        permissions: ["1", "2"],
      },
    ],
    permissions: ["1", "2"],
  },
  {
    id: "loyalty",
    title: "Loyalty",
    type: "collapse",
    icon: <Icon.Award size={20} />,
    children: [
      {
        id: "tiers",
        title: "Tiers",
        type: "item",
        icon: <Icon.Layers size={20} />,
        navLink: "/loyalty/tiers",
        permissions: ["1", "2"],
      },
      {
        id: "rules",
        title: "Rules",
        type: "item",
        icon: <Icon.AlertTriangle size={20} />,
        navLink: "/loyalty/rules",
        permissions: ["1", "2"],
      },
      {
        id: "pointsShop",
        title: "Points Shop",
        type: "item",
        icon: <Icon.ShoppingCart size={20} />,
        navLink: "/loyalty/points-shops",
        permissions: ["1", "2"],
      },
      {
        id: "punchCard",
        title: "Punch Cards",
        type: "item",
        icon: <Icon.CreditCard size={20} />,
        navLink: "/loyalty/punch-cards",
        permissions: ["1", "2"],
      },
    ],
    permissions: ["1", "2"],
  },
  {
    id: "referralSystem",
    title: "Referral System",
    type: "item",
    icon: <Icon.UserPlus size={20} />,
    navLink: "/referral",
    permissions: ["1", "2"],
  },
  {
    id: "crm",
    title: "CRM",
    type: "collapse",
    icon: <Icon.Share2 size={20} />,
    children: [
      {
        id: "campaign",
        title: "Marketing Campaigns",
        type: "item",
        icon: <Icon.Send size={20} />,
        navLink: "/campaign/list",
        permissions: ["1", "2"],
      },
      {
        id: "customer",
        title: "Customers",
        type: "item",
        permissions: ["1", "2"],
        icon: <Icon.Users size={20} />,
        navLink: "/customer/list",
      },
    ],
  },
  {
    id: "prizes",
    title: "Prizes",
    type: "collapse",
    icon: <Icon.Gift size={20} />,
    children: [
      {
        id: "prizes list",
        title: "Prizes List",
        type: "item",
        icon: <Icon.List size={20} />,
        navLink: "/prizes",
        permissions: ["1", "2"],
      },
      {
        id: "rewardLookUp",
        title: "Prize Look Up",
        type: "item",
        icon: <Icon.Search size={20} />,
        navLink: "/reward-lookup",
        permissions: ["1", "2", "3"],
      },
    ],
    permissions: ["1", "2", "3"],
  },
  {
    id: "discounts",
    title: "Discounts",
    type: "item",
    permissions: ["1"],
    icon: <Icon.Percent size={20} />,
    navLink: "/discounts/list",
  },
  {
    id: "visualAds",
    title: "Visual Ads",
    type: "collapse",
    icon: <Icon.Tv size={20} />,
    children: [
      {
        id: "Sliders",
        title: "Sliders",
        type: "item",
        icon: <Icon.Image size={20} />,
        navLink: "/sliders",
      },
      {
        id: "Pop Ups",
        title: "Pop Ups",
        type: "item",
        icon: <Icon.Film size={20} />,
        navLink: "/pop-ups",
      },
    ],
    permissions: ["1", "2"],
  },
  {
    id: "settings",
    title: "Settings",
    type: "item",
    permissions: ["1", "2"],
    icon: <Icon.Settings size={20} />,
    navLink: "/settings",
  },
];

export default navigationConfig;
